var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box-code" },
    [
      false
        ? _c(
            "div",
            {
              staticClass: "navs",
              staticStyle: { "background-color": "#f5c319" },
            },
            [
              _c("el-input", {
                staticClass: "gullters",
                staticStyle: { display: "inline-block", width: "200px" },
                attrs: { size: _vm.size, placeholder: "请输入作品名称" },
                model: {
                  value: _vm.formInfo.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "title", $$v)
                  },
                  expression: "formInfo.title",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "gullters",
                  staticStyle: { display: "inline-block" },
                  attrs: { type: "primary", size: _vm.size },
                  on: {
                    click: function ($event) {
                      return _vm.openWinEditor("save")
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
              _vm.formInfo.id && _vm.formInfo.id !== _vm.taskInfo.sourceId
                ? _c(
                    "el-button",
                    {
                      staticClass: "gullters",
                      staticStyle: { display: "inline-block" },
                      attrs: { type: "primary", size: _vm.size },
                      on: {
                        click: function ($event) {
                          return _vm.openWinEditor("other")
                        },
                      },
                    },
                    [_vm._v(" 另存为 ")]
                  )
                : _vm._e(),
              _vm.buttons.works
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: _vm.size },
                      on: { click: _vm.showList },
                    },
                    [_vm._v(" 提交记录 ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("iframe", {
        ref: "iframes",
        staticClass: "iframe-style",
        attrs: {
          id: "scratchjriframe",
          src: _vm.iframeWinPaht,
          frameborder: "0",
          seamless: "",
          allowfullscreen: "true",
          sandbox:
            "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
        },
      }),
      _c("editor", {
        ref: "editor",
        attrs: { formInfos: _vm.formInfo, rules: _vm.rules, title: "提交表单" },
        on: { saveFile: _vm.saveFile },
      }),
      _c("work-list", {
        ref: "list",
        attrs: { taskId: _vm.taskInfo.id },
        on: { showItem: _vm.showItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }